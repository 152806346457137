import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

function ImageUploader() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async (file) => {
    setLoading(true); // Show loading screen
    const formData = new FormData();
    formData.append('file', file);

    try {
      const url = 'http://localhost:8000/match-island/';
      const res = await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setResponse(res.data);
    } catch (error) {
      console.error('Error uploading the image:', error);
    } finally {
      setLoading(false); // Hide loading screen
    }
  };

  const handlePaste = (event) => {
    const items = event.clipboardData.items;
    for (const item of items) {
      if (item.kind === 'file' && item.type.startsWith('image/')) {
        const file = item.getAsFile();
        
        if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
          const reader = new FileReader();
          reader.onload = function (event) {
            const img = new Image();
            img.src = event.target.result;
            img.onload = () => {
              const canvas = document.createElement('canvas');
              canvas.width = img.width;
              canvas.height = img.height;
              const ctx = canvas.getContext('2d');
              ctx.drawImage(img, 0, 0);
              canvas.toBlob((blob) => {
                const jpegFile = new File([blob], "pasted-image.jpg", { type: 'image/jpeg' });
                setSelectedFile(jpegFile);
                handleUpload(jpegFile);
              }, 'image/jpeg');
            };
          };
          reader.readAsDataURL(file);
        } else {
          setSelectedFile(file);
          handleUpload(file);
        }
      }
    }
  };
  
  useEffect(() => {
    document.addEventListener('paste', handlePaste);
    return () => {
      document.removeEventListener('paste', handlePaste);
    };
  }, []);

  return (
    <div className="container mt-5">
      {loading && (
        <div className="loading-screen">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      <div className={`card ${loading ? 'd-none' : ''}`}>
        <div className="card-header">
          <h2 className="card-title">Isle Finder</h2>
        </div>
        <div className="card-body">
          <div className="mb-3">
            <input 
              type="file" 
              className="form-control" 
              accept="image/*" 
              capture="environment" 
              onChange={handleFileChange} 
            />
          </div>
          <button onClick={() => handleUpload(selectedFile)} className="btn btn-primary">
            Upload Image
          </button>
        </div>
        {response && (
          <div className="card-footer">
            {response.name && (
              <div>
                <p><strong>Island Name:</strong> {response.name}</p>
                <p><strong>Confidence Score:</strong> {response.confidence_score}</p>
                <p><strong>Coordinates:</strong> {response.coordinates}</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default ImageUploader;
